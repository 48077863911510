import React from 'react'

import './FormHeader.scss'

function FormHeading(props) {
  const { num = 1, label = 'Form Header' } = props

  return (
    <header className="FormHeader">
      <span className="FormHeader__num">{num}</span>
      <h3 className="FormHeader__label">{label}</h3>
    </header>
  )
}

export default FormHeading
