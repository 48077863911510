import React from 'react'
import jstz from 'jstimezonedetect'

import Button from 'Components/Button'
import FormHeader from 'Components/FormHeader'
import TextInput from './TextInput'
import SubDetails from './SubDetails'
import useFormState, {
  emailValidator,
  nonEmptyValidator,
} from 'hooks/useFormState'

import './SignupFormView.scss'

function SignupFormView(props) {
  const { order, onSubmit } = props
  const { orgName, subscription } = order
  const {
    userCount,
    productName,
    planName,
    trialDays,
    trialExpiry,
  } = subscription

  const [propsForField, form] = useFormState(
    makeFormConfig({
      team: orgName,
      timezone: jstz.determine().name(),
      users: userCount,
    })
  )

  const handleSubmit = () => {
    if (form.allErrors().length > 0) {
      return
    }

    const rawData = form.formData

    const parsedData = {}
    Object.keys(rawData).forEach((key) => {
      const [field, index, subfield] = key.split('.')
      if (index === undefined) {
        parsedData[field] = rawData[key]
      } else {
        parsedData[field] = parsedData[field] || []
        const idx = parseInt(index, 10)
        parsedData[field][idx] = parsedData[field][idx] || {}
        parsedData[field][idx][subfield] = rawData[key]
      }
    })

    onSubmit({ ...order, config: parsedData })
  }

  let stepNum = 0

  return (
    <div className="SignupForm">
      <div className="SignupForm__header">Complete Sign Up Details</div>
      <div className="SignupForm__body">
        <div className="SignupForm__secondary-pane">
          <SubDetails
            product={productName}
            plan={planName}
            trialDays={trialDays}
            trialExpiry={trialExpiry}
          />
        </div>
        <div className="SignupForm__primary-pane">
          <section>
            <FormHeader num={++stepNum} label="Company Details" />
            <div className="SignupForm__fieldset">
              <div className="SignupForm__field SignupForm__field--full">
                <TextInput
                  label="Company Name"
                  {...propsForField('team')}
                  readOnly
                />
              </div>
            </div>
          </section>
          <section>
            <FormHeader num={++stepNum} label="User Details" />
            <div className="SignupForm__fieldset">
              {range(userCount).map((i) => [
                <div key="head" className="SignupForm__subhead">
                  <span>User {i + 1}</span>
                  <div className="subhead-spacer" />
                </div>,
                userCount > 1 && i === 0 ? (
                  <p key="tl-note" className="SignupForm__tl-notice">
                    This user will be designated as the team leader and be able
                    to view reporting data for all RoboReview submissions in
                    your organization.
                  </p>
                ) : null,
                <div key="fname" className="SignupForm__field">
                  <TextInput
                    label="First Name"
                    {...propsForField(`employees.${i}.firstname`)}
                  />
                </div>,
                <div key="lname" className="SignupForm__field">
                  <TextInput
                    label="Last Name"
                    {...propsForField(`employees.${i}.lastname`)}
                  />
                </div>,
                <div
                  key="email"
                  className="SignupForm__field SignupForm__field--full"
                >
                  <TextInput
                    label="Email"
                    {...propsForField(`employees.${i}.email`)}
                  />
                </div>,
              ])}
            </div>
          </section>
          <section>
            <FormHeader num={++stepNum} label="Comments / Notes" />
            <div className="SignupForm__fieldset">
              <div className="SignupForm__field SignupForm__field--full">
                <TextInput
                  {...propsForField(`comments`)}
                  help="Any additional comments regarding the set up of your subscription"
                  multiline
                />
              </div>
            </div>
          </section>
          <section>
            <Button
              className="SignupForm__submit"
              onClick={handleSubmit}
              label="Submit Details"
              large
            />
          </section>
        </div>
      </div>
    </div>
  )
}

export default SignupFormView

function range(length, start = 0) {
  return Array.from({ length }, (_, i) => i + start)
}

function makeFormConfig({ team = '', timezone = '', users = 1 } = {}) {
  const initialState = {
    team,
    timezone,
    comments: '',
  }

  const validators = {}

  range(users).forEach((i) => {
    initialState[`employees.${i}.firstname`] = ''
    initialState[`employees.${i}.lastname`] = ''
    initialState[`employees.${i}.email`] = ''

    validators[`employees.${i}.firstname`] = nonEmptyValidator(
      'First name is required'
    )
    validators[`employees.${i}.lastname`] = nonEmptyValidator(
      'Last name is required'
    )
    validators[`employees.${i}.email`] = emailValidator()
  })

  return { initialState, validators }
}
