import React from 'react'

import Button from 'Components/Button'
import TextInput from '../SignupFormView/TextInput'
import useFormState, {
  emailValidator,
  nonEmptyValidator,
} from 'hooks/useFormState'

function UserVerificationView(props) {
  const { customer_id = '', email = '', onVerify, error = null } = props
  const [propsForField, form] = useFormState({
    initialState: { customer_id, email },
    validators: {
      customer_id: nonEmptyValidator('Customer ID is required'),
      email: emailValidator(),
    },
  })

  const handleVerify = () => {
    if (form.allErrors().length > 0) {
      return
    }

    const { email, customer_id } = form.formData
    console.log(email, customer_id)
    onVerify(email, customer_id)
  }

  return (
    <div className="SignupForm__fieldset" style={{ margin: '0 20px' }}>
      <p style={{ textAlign: 'center', width: '100%' }}>
        Please verify your subscription to continue.
      </p>
      <div className="SignupForm__field SignupForm__field--full">
        <TextInput label="Email Address" {...propsForField('email')} />
      </div>
      <div className="SignupForm__field SignupForm__field--full">
        <TextInput
          label="Customer ID"
          {...propsForField('customer_id')}
          help="You can find your customer ID in your sign up email"
        />
      </div>
      <div className="SignupForm__field SignupForm__field--full">
        <Button onClick={handleVerify} label="Verify Details" />
        {error !== null && (
          <p
            style={{
              textAlign: 'center',
              width: '100%',
              marginTop: 8,
              marginBottom: 0,
              color: 'rgb(191, 34, 49)',
            }}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  )
}

export default UserVerificationView
