import React from 'react'

import './SubDetails.scss'

function SubDetails(props) {
  const { product, plan, trialDays = 0, trialExpiry } = props

  const formatDate = (d) =>
    new Date(d).toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    })

  return (
    <div className="SubDetails">
      <div className="SubDetails__heading">
        <h3>Subscription Details</h3>
      </div>
      <div className="SubDetails__content">
        <p className="SubDetails__product semi-bold order-summary-productname word-wrap-break">
          {product}
        </p>
        <p className="SubDetails__plan">{plan}</p>
        {trialDays > 0 && (
          <>
            <hr className="SubDetails__divider" />
            <p className="SubDetails__plan">
              <strong style={{ fontWeight: 600 }}>Trial period</strong>:<br />
              Includes {trialDays} day free trial (expires{' '}
              {formatDate(trialExpiry)})
            </p>
          </>
        )}
      </div>
    </div>
  )
}

export default SubDetails
