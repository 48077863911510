import React from 'react'
import queryString from 'query-string'

function MailtoLink(props) {
  const {
    to,
    cc = undefined,
    bcc = undefined,
    subject = undefined,
    body = undefined,
    children = to,
  } = props

  let href = `mailto:${to}`
  const params = queryString.stringify({ cc, bcc, subject, body })
  if (params.length) {
    href += `?${params}`
  }
  return <a href={href}>{children}</a>
}

export default MailtoLink
