import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './TextInput.scss'

function TextInput(props) {
  const {
    value,
    onChange,
    onBlur = null,
    label = null,
    readOnly = false,
    multiline = false,
    help = null,
    error = null,
    complete = false,
  } = props

  const statusIcon =
    error !== null ? (
      <span className="TextInput__status TextInput__status--error">
        <FontAwesomeIcon icon="exclamation-circle" />
      </span>
    ) : complete && !readOnly ? (
      <span className="TextInput__status TextInput__status--complete">
        <FontAwesomeIcon icon="check-circle" />
      </span>
    ) : null

  return (
    <React.Fragment>
      {multiline ? (
        <textarea
          className="TextInput"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          lines={3}
          readOnly={readOnly}
        />
      ) : (
        <input
          type="text"
          className={
            statusIcon !== null
              ? 'TextInput TextInput--has-status'
              : 'TextInput'
          }
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onBlur={onBlur}
          readOnly={readOnly}
        />
      )}
      {label !== null && <span className="TextInput__label">{label}</span>}
      {!multiline && statusIcon}
      {error !== null ? (
        <p className="TextInput__help TextInput__help--error">{error}</p>
      ) : help !== null ? (
        <p className="TextInput__help">{help}</p>
      ) : null}
    </React.Fragment>
  )
}

export default TextInput
