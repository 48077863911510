import React from 'react'

import logo from 'assets/logo-robosuite-312x91.png'

import './AppLayout.scss'

function AppLayout(props) {
  const { children } = props
  return (
    <div className="AppLayout">
      <header>
        <img src={logo} width="312" height="91" alt="Reveal RoboSuite™" />
      </header>

      {children}
    </div>
  )
}

export default AppLayout
