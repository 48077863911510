import { useState } from 'react'

function useFormState({ initialState, validators }) {
  const [formState, setFormState] = useState({
    data: initialState,
    touched: {},
    errors: {},
  })

  const valueForField = (field) => {
    return formState.data[field]
  }

  const touchedForField = (field) => {
    return formState.touched[field] === true
  }

  const errorForField = (field) => {
    return touchedForField(field) ? formState.errors[field] : null
  }

  const handleChangeForField = (field, value) => {
    const validate = validators[field] || null
    setFormState((prevState) => ({
      ...prevState,
      data: {
        ...prevState.data,
        [field]: value,
      },
      errors: {
        ...prevState.errors,
        [field]: validate === null ? null : validate(value),
      },
    }))
  }

  const handleBlur = (field) => {
    const validate = validators[field] || null
    setFormState((prevState) => ({
      ...prevState,
      touched: {
        ...prevState.touched,
        [field]: true,
      },
      errors: {
        ...prevState.errors,
        [field]: validate === null ? null : validate(prevState.data[field]),
      },
    }))
  }

  const propsForField = (field) => {
    const error = errorForField(field)

    return {
      value: valueForField(field),
      error,
      complete: touchedForField(field) && error === null,
      onChange: (e) => {
        const value = e?.target?.value ?? e
        handleChangeForField(field, value)
      },
      onBlur: () => handleBlur(field),
    }
  }

  const validateAll = () => {
    setFormState((prevState) => {
      let willUpdate
      const newErrors = {}
      const newTouched = {}

      Object.keys(validators).forEach((f) => {
        const error = validators[f](prevState.data[f])
        if (error !== prevState.errors[f]) {
          willUpdate = true
          newErrors[f] = error
          newTouched[f] = true
        }
      })

      if (!willUpdate) {
        return prevState
      }

      return {
        ...prevState,
        errors: {
          ...prevState.errors,
          ...newErrors,
        },
        touched: {
          ...prevState.touched,
          ...newTouched,
        },
      }
    })
  }

  const allErrors = () => {
    // We're in a weird spot here, we want to trigger a revalidation so that
    // the form state reflects any invalid data, but we also need to manually
    // validate the current state so that we can synchronously return a list of
    // errors. There's a very small potential for a mismatch there if there are
    // outstanding state updates to process.
    validateAll()

    const currentErrors = Object.keys(validators)
      .map((f) => ({ field: f, error: validators[f](formState.data[f]) }))
      .filter((e) => e.error !== null)

    return currentErrors
  }

  return [
    propsForField,
    {
      formData: formState.data,
      valueForField,
      handleChangeForField,
      validateAll,
      allErrors,
    },
  ]
}

export default useFormState

export function nonEmptyValidator(msg = 'Field is required') {
  return (value = '') => (Boolean(value) ? null : msg)
}

export function emailValidator(msg = 'A valid email address is required') {
  return (value = '') => (/^\S+@\S+\.\S+$/.test(value) ? null : msg)
}
