import React, { useEffect, useLayoutEffect, useState } from 'react'
import queryString from 'query-string'

import AppLayout from './AppLayout'
import MailtoLink from 'Components/MailtoLink'
import NoticeBox from './NoticeBox'
import SignupFormView from 'SignupFormView'
import UserVerificationView from 'UserVerificationView'

import { API_HOST, API_KEY } from 'appConstants'

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faSpinner,
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons'

library.add(faSpinner, faCheckCircle, faExclamationCircle)

function SignupFormApp(props) {
  const [credentials, setCredentials] = useState(() => {
    const { hostedpage } = queryString.parse(window.location.search)
    return hostedpage ? { hostedpage } : {}
  })
  const [authToken, setAuthToken] = useState(null)
  const [appState, setAppState] = useState({})

  /*
  App States:
    0.0 unauthenticated, { error: string } : unauthenticated, with optional error for failed auth
    0.1 authenticating

    1.1. verifyingOrder, {} : fetching order via API
    1.2. verifyOrderFailed, { error: string } : order fetch failed
    1.3. orderConfigExists, {} : config previously submitted
    1.4. orderConfigRequired, { organisation: string, userCount: number } : order fetch successful, user to complete form

    2.1. submittingConfig, {} : submissing config
    2.2. submitConfigFailed, { error: string } : config submission failed
    2.3. submitConfigSucceeded, {} : config submission successful

    3. internalError, { error: string }
*/

  useLayoutEffect(() => {
    // Post a message to the parent window, asking it to scroll to top
    // Requires a listened on that window to be listening for the message and respond correctly
    console.log(`posting msg 'scrollToTop'`)
    window.parent.postMessage('scrollToTop', 'https://www.revealgroup.com')
  }, [appState.status])

  useEffect(() => {
    if (
      credentials.hostedpage === undefined &&
      (credentials.customer_id === undefined || credentials.email === undefined)
    ) {
      setAppState({ status: 'unauthenticated' })
      return
    }

    authenticate()

    async function authenticate() {
      setAppState({ status: 'authenticating' })

      try {
        const query = queryString.stringify(
          { code: API_KEY },
          { encode: false }
        )

        const postAuth = await fetch(`${API_HOST}/api/authenticate?${query}`, {
          method: 'post',
          body: JSON.stringify(credentials),
        })

        if (!postAuth.ok) {
          if (postAuth.status === 401) {
            setAppState({
              status: 'unauthenticated',
              error: 'Could not verify identity',
            })
          } else {
            setAppState({
              status: 'authenticationError',
              error: `Could not authenticate (${postAuth.status})`,
            })
          }
          return
        }

        const authData = await postAuth.json()
        setAuthToken(authData.data)
        setAppState({ status: '@REMOVE.authenticated' })
      } catch (e) {
        setAppState({ status: 'authenticationError', error: e.toString() })
      }
    }
  }, [credentials])

  useEffect(() => {
    if (authToken === null) {
      return
    }

    getSubscription()

    async function getSubscription() {
      setAppState({ status: 'verifyingOrder' })

      const query = queryString.stringify({ code: API_KEY }, { encode: false })
      const getSub = await fetch(`${API_HOST}/api/subscriptions/me?${query}`, {
        method: 'get',
        headers: { authorization: `Bearer ${authToken}` },
      })

      if (!getSub.ok) {
        setAppState({
          status: 'verifyOrderFailed',
          error: getSub.status,
        })
      }

      const subData = await getSub.json()

      if (subData.status === 'failed') {
        if (subData.message === 'Details previously submitted') {
          setAppState({ status: 'orderConfigExists' })
        } else {
          setAppState({ status: 'verifyOrderFailed', error: subData.message })
        }
        return
      }

      setAppState({
        status: 'orderConfigRequired',
        order: subData.data,
      })
    }
  }, [authToken])

  if (appState.status === 'unauthenticated') {
    const handleVerify = (email, customer_id) => {
      setCredentials({ email, customer_id })
    }

    const { customer_id = '', email = '' } = credentials || {}
    return (
      <AppLayout>
        <NoticeBox>
          <UserVerificationView
            customer_id={customer_id}
            email={email}
            error={appState.error}
            onVerify={handleVerify}
          />
        </NoticeBox>
      </AppLayout>
    )
  }

  if (appState.status === 'authenticating') {
    return (
      <AppLayout>
        <NoticeBox
          icon="spinner"
          iconProps={{ pulse: true, style: { color: 'rgb(182, 182, 182)' } }}
        >
          <p>Authenticating...</p>
        </NoticeBox>
      </AppLayout>
    )
  }
  if (appState.status === '@REMOVE.authenticated') {
    return (
      <AppLayout>
        <NoticeBox
          icon="check-circle"
          iconProps={{ style: { color: 'rgb(14, 182, 97)' } }}
        >
          <p>Auth'd pew pew</p>
        </NoticeBox>
      </AppLayout>
    )
  }

  if (appState.status === 'internalError') {
    return (
      <AppLayout>
        <NoticeBox
          icon="exclamation-circle"
          iconProps={{ style: { color: 'rgb(191, 34, 49)' } }}
        >
          <p>
            Sorry, something has gone wrong while trying to complete sign up!
            Please contact <MailtoLink to="support@revealgroup.com" /> for
            assistance, referencing the following error:
          </p>
          <p style={{ color: 'rgb(191, 34, 49)' }}>{appState.error}</p>
          <p>
            (Or{' '}
            <MailtoLink
              to="support@revealgroup.com"
              subject="Reveal RoboSuite™ sign up: internal error"
              body={`Encountered the follow error: ${appState.error}`}
            >
              click here
            </MailtoLink>{' '}
            to create a pre-filled email.)
          </p>
        </NoticeBox>
      </AppLayout>
    )
  }

  if (appState.status === 'verifyingOrder') {
    return (
      <AppLayout>
        <NoticeBox
          icon="spinner"
          iconProps={{ pulse: true, style: { color: 'rgb(182, 182, 182)' } }}
        >
          <p>Verifying your order...</p>
        </NoticeBox>
      </AppLayout>
    )
  }

  if (appState.status === 'verifyOrderFailed') {
    return (
      <AppLayout>
        <NoticeBox
          icon="exclamation-circle"
          iconProps={{ style: { color: 'rgb(191, 34, 49)' } }}
        >
          <p>Something went wrong while trying to complete sign up.</p>
          <p>
            Please contact <MailtoLink to="support@revealgroup.com" /> for
            assistance, referencing the following error:
          </p>
          <p style={{ color: 'rgb(191, 34, 49)' }}>{appState.error}</p>
          <p>
            (Or{' '}
            <MailtoLink
              to="support@revealgroup.com"
              subject="Reveal RoboSuite™ sign up: error verifying order"
              body={`Encountered the follow error: ${appState.error}`}
            >
              click here
            </MailtoLink>{' '}
            to create a pre-filled email.)
          </p>
        </NoticeBox>
      </AppLayout>
    )
  }

  if (appState.status === 'orderConfigExists') {
    return (
      <AppLayout>
        <NoticeBox
          icon="check-circle"
          iconProps={{ style: { color: 'rgb(14, 182, 97)' } }}
        >
          <p>
            You have already completed the sign up process for your
            subscription.
          </p>
          <p>
            Once your subscription is set up you will receive an email from{' '}
            <MailtoLink to="support@revealgroup.com" />. Please allow up to 24
            hours for set up to be completed.
          </p>
        </NoticeBox>
      </AppLayout>
    )
  }

  if (appState.status === 'orderConfigRequired') {
    async function submitConfig(config) {
      setAppState({ status: 'submittingConfig' })
      const query = queryString.stringify({ code: API_KEY }, { encode: false })

      const postConfig = await fetch(
        `${API_HOST}/api/subscriptions/me/details?${query}`,
        {
          method: 'post',
          headers: { authorization: `Bearer ${authToken}` },
          body: JSON.stringify(config, null, 2),
        }
      )

      if (!postConfig.ok) {
        setAppState({
          status: 'submitConfigFailed',
          error: `Failed to submit config (${postConfig.status})`,
        })
        return
      }

      setAppState({ status: 'submitConfigSucceeded' })
    }

    return (
      <AppLayout>
        <SignupFormView order={appState.order} onSubmit={submitConfig} />
      </AppLayout>
    )
  }

  if (appState.status === 'submittingConfig') {
    return (
      <AppLayout>
        <NoticeBox
          icon="spinner"
          iconProps={{ pulse: true, style: { color: 'rgb(182, 182, 182)' } }}
        >
          <p>Submitting details...</p>
        </NoticeBox>
      </AppLayout>
    )
  }

  if (appState.status === 'submitConfigFailed') {
    return (
      <AppLayout>
        <NoticeBox
          icon="exclamation-circle"
          iconProps={{ style: { color: 'rgb(191, 34, 49)' } }}
        >
          <p>Something went wrong while trying to submit your details.</p>
          <p>
            Please contact <MailtoLink to="support@revealgroup.com" /> for
            assistance, referencing the following error:
          </p>
          <p style={{ color: 'rgb(191, 34, 49)' }}>{appState.error}</p>
          <p>
            (Or{' '}
            <MailtoLink
              to="support@revealgroup.com"
              subject="Reveal RoboSuite™ sign up: error submitting details"
              body={`Encountered the follow error: ${appState.error}`}
            >
              click here
            </MailtoLink>{' '}
            to create a pre-filled email.)
          </p>
        </NoticeBox>
      </AppLayout>
    )
  }

  if (appState.status === 'submitConfigSucceeded') {
    return (
      <AppLayout>
        <NoticeBox
          icon="check-circle"
          iconProps={{ style: { color: 'rgb(14, 182, 97)' } }}
        >
          <p>
            Your details have been received and you will receive an email when
            your access has been set up and is ready to use.
          </p>
          <p>
            Please allow up to 24 hours for this to be completed. If you need
            any help please contact us at{' '}
            <MailtoLink
              to="support@revealgroup.com"
              subject="Reveal RoboSuite™ sign up"
            />
            .
          </p>
        </NoticeBox>
      </AppLayout>
    )
  }

  // Default / start up state
  return <AppLayout />
}

export default SignupFormApp
