import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import './NoticeBox.scss'

function NoticeBox(props) {
  const { icon = null, iconProps = {}, children } = props
  return (
    <div className="NoticeBox">
      {icon !== null && (
        <FontAwesomeIcon
          className="NoticeBox__icon"
          size="4x"
          icon={icon}
          {...iconProps}
        />
      )}
      {children}
    </div>
  )
}

export default NoticeBox
