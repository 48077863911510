import React from 'react'

import './Button.scss'

function Button(props) {
  const { label, onClick, large, className = null } = props

  const btnClass = ['Button', large ? 'Button--large' : null, className]
    .filter(Boolean)
    .join(' ')

  return (
    <button className={btnClass} onClick={onClick}>
      {label}
    </button>
  )
}

export default Button
